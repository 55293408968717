<template>
  <p v-if="showOutput">{{hello}}</p>
  <input v-on:change="hideOutput" v-model="hello"/>
  <button v-on:click="alertHello">hello</button>

</template>
<script>

  export default{
    name: 'About',
    data(){
      return{
        hello: "Hello Dude",
        showOutput: false
      }
    },
    methods:{
      alertHello(){
        this.showOutput= true;
      },
      hideOutput(){
        this.showOutput = false;
      }
    }

  }
</script>
